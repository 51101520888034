<template lang="pug">
div(class='grid grid-cols-1 gap-4 lg:grid-cols-40-60 lg:gap-12')
  div(v-if='showMap' class='lg:order-last lg:relative')
    div(class='h-[340px] lg:sticky lg:top-3 lg:pb-6 lg:h-screen')
      div(id='mapElement' class='w-full h-full bg-gray-lighter')
  div(:class='{ "col-span-full": !showMap }')
    //- While initial loading
    template(v-if='isLoading')
      SpinnerText(text='Loading' is-dark)

    //- NOTE: We are explicitly not using the search components here and doing this from the ground up
    //- because of the complex sorting that is required.
    //- state when we have atleast a single location
    template(v-else-if='limitedResults.length > 0')
      p(v-if='showResultCount' class='mb-4') {{ numberize(limitedResults.length, 'Dealer') }} within {{ radius }} miles of {{ location }}

      p(v-if='showPleaseNote') #[span(class='text-danger-dark font-bold') Please note:] Not all dealers stock inventory. Please call dealer to confirm product availability.

      div(:class='{ "divide-y divide-gray-light border-t border-gray-light mt-4": limitedResults.length > 1 }')
        template(v-for='(result, index) in limitedResults' :key='index')
          div(class='py-4')
            slot(:key='result.id' name='result' :result='result')
              DealerLocatorDealer(:dealer='result' :map='map' @focus='dealerFocused')

    //- Fallback when we have nothing to show
    template(v-else)
      slot(name='empty')
        p Sorry, there are no locations within your search area. Please try expanding your search.
    slot(v-if='results.length > limitedResults.length' name='showMore')
//- Only render when we don't override result slot aka pdp page
DealerLocatorDoubleWarrantyModal(v-if='!$slots.result')
</template>

<script setup lang="ts">
const { $algolia, $geolocation, $googlePlaces } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const dealerLocator = $algolia.searchContexts.dealerLocator
const dealerData = useDealerLocator()
const isLoading = ref(true)

// shallowRef means not deeply reactive
const map = shallowRef()
let infoWindow: any

const {
  showPleaseNote = true,
  showMap = false,
  showResultCount = false,
  limitResults,
  brand,
} = defineProps<{
  showPleaseNote?: boolean
  showMap?: boolean
  showResultCount?: boolean
  limitResults?: number
  brand?: string
}>()

onMounted(async () => {
  if (brand) dealerData.setBrand(brand)

  if (!isIntegrationEnabled('googlePlaces')) return
  await $googlePlaces.load()
  if (showMap) initMap()
  await initLocation()
})

function initMap() {
  const mapElement = document.getElementById('mapElement') as HTMLInputElement

  // Create map instance
  map.value = new window.google.maps.Map(mapElement, {
    zoom: 4,
    center: { lat: 37.0902, lng: -95.7129 },
    maxZoom: 16,
    fullscreenControl: false,
    disableDefaultUI: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
    streetViewControl: false,
  })

  // Create infoWindow instance
  infoWindow = new window.google.maps.InfoWindow({
    maxWidth: 290,
  })
}

async function initLocation() {
  // We can't do this if the geolocation feature isn't enabled
  // We have to make sure to set loading to false here because we are also making sure we have a zipcode.
  // Skip if we already have a dealerData location
  if (
    !isIntegrationEnabled('geolocation') ||
    !$geolocation.latitude ||
    !$geolocation.longitude ||
    dealerData.state.value.location
  ) {
    isLoading.value = false
    return
  }

  try {
    dealerData.setLocation($geolocation.latitude, $geolocation.longitude, $geolocation.formattedLocationWithZip)
    dealerData.setRadius(dealerData.state.value.radius)
    await dealerData.performSearch()
  } catch (e) {
    console.log('Unable to geocode location', e)
  } finally {
    isLoading.value = false
  }
}

function dealerFocused(event) {
  const { marker, content } = event

  const bounds = new window.google.maps.LatLngBounds(marker.getPosition())

  // Center map around new bounding location center
  map.value.setCenter(bounds.getCenter())

  // Make the map fit the bounds of the new data set
  map.value.fitBounds(bounds)

  // Update info window to focused dealer
  infoWindow.setOptions({
    content,
  })

  // Set the infoWindow position to the marker
  infoWindow.open({
    anchor: marker,
  })
}

const limitedResults = computed(() => {
  if (limitResults) {
    return results.value.slice(0, limitResults)
  }
  return results.value
})

const results = computed(() => {
  const results = dealerLocator.indexes
    .map((index) => {
      if (index.key === 'brand' && index.state.value.activeRefinementCount === 0) return []
      return index.state.value.results
    })
    .flat()

  return results
})

const radius = computed(() => {
  return dealerData.state.value.radius
})

const location = computed(() => {
  return dealerData.state.value.location
})

watch(results, (newVal) => {
  // If we don't have a map, then google has not loaded yet. which means we don't have a valid search yet.
  // We use google to get the geolocation of the place you are looking for so no google means, no search
  if (!map.value) return

  // If we didn't find any results then reset the map back to the default view
  if (newVal.length === 0) {
    map.value.setOptions({ zoom: 4, center: { lat: 37.0902, lng: -95.7129 } })
    return
  }

  // Create bounding obj
  const bounds = new window.google.maps.LatLngBounds()
  // Add new results to the bounding obj
  newVal.forEach((result) => bounds.extend(result._geoloc))

  // Center map around new bounding location center
  map.value.setCenter(bounds.getCenter())

  // Make the map fit the bounds of the new data set.
  // The 20 will add an extra 20px around the edge of the map so none of the points are right on the edge of the map.value.
  map.value.fitBounds(bounds, 20)
})
</script>
